import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { usePullData } from "../hooks/usePullData";
import { renderCollection } from "../util/renderCollection";
import RichText from "./RichText";
import { v4 as uuid } from "uuid";

const collectionSlug = "classes";

const Classes = ({ data, cmsData }) => {
	const location = useLocation();
	const [filteredData, setFilteredData] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState("All");
	const [categories, setCategories] = useState([]);

	// Handle both URL hash and navigation state changes
	useEffect(() => {
		if (location.state?.category) {
			setSelectedFilter(location.state.category);
			window.history.replaceState(
				null,
				"",
				`#${encodeURIComponent(location.state.category)}`
			);
		} else if (location.hash) {
			const category = decodeURIComponent(location.hash.slice(1));
			setSelectedFilter(category);
		}
	}, [location]);

	useEffect(() => {
		if (data) {
			// Build categories array
			let nonUniqueCategories = [];
			data.forEach((item) => {
				item.attributes.category.forEach((cat) => {
					nonUniqueCategories.push(cat.value);
				});
			});
			let uniqueCategories = [...new Set(nonUniqueCategories)].sort();
			setCategories(uniqueCategories);

			// Filter and sort data
			let filteredItems = [];
			if (selectedFilter === "All") {
				filteredItems = [...data].sort((a, b) =>
					a.attributes.title
						.toLowerCase()
						.localeCompare(b.attributes.title.toLowerCase())
				);
			} else {
				filteredItems = data.filter((item) =>
					item.attributes.category.some((cat) => cat.value === selectedFilter)
				);

				// Apply special sorting for "Dogs 6 months +"
				if (selectedFilter === "Dogs 6 months +") {
					const orderMap = {
						"Beginner Obedience Class": 1,
						"Advanced Obedience Class": 2,
						"Master Obedience Class": 3,
					};

					filteredItems.sort((a, b) => {
						const orderA = orderMap[a.attributes.title] || 4;
						const orderB = orderMap[b.attributes.title] || 4;
						return orderA - orderB;
					});
				}
			}

			setFilteredData(filteredItems);
		}
	}, [data, selectedFilter]);

	const handleFilterClick = (filter) => {
		setSelectedFilter(filter);
		// Update the URL without triggering navigation
		window.history.replaceState(null, "", `#${encodeURIComponent(filter)}`);
	};

	return (
		<div className="classeslistsection wf-section">
			<div className="listcontainer w-container">
				<div className="classeslistfilters">
					<div
						className={`classesfilter ${
							selectedFilter === "All" && "filterActive"
						}`}
						onClick={() => handleFilterClick("All")}
					>
						All
					</div>

					{categories.map((el) => {
						return (
							<div
								key={uuid()}
								className={`classesfilter ${
									selectedFilter === el && "filterActive"
								}`}
								onClick={() => handleFilterClick(el)}
							>
								{el}
							</div>
						);
					})}

					<a
						href="https://www.thedogscents.com/"
						target="_blank"
						rel="noreferrer"
						className="classesfilter dogscentslink"
					>
						TheDogScents
					</a>
				</div>
				<div className="classeslist">
					{renderCollection(CollectionEntry, filteredData, collectionSlug)}
				</div>
			</div>
		</div>
	);
};

const CollectionEntry = (props) => {
	// we can now access any of our strapi fields using data.fieldName
	let data = usePullData(props);
	// This code gets repeated once per database entry in strapi.
	return (
		<div className="classinfo">
			<div className="classinfoheader">
				<h2 className="classheading">{data.title}</h2>
				<a
					href={data.registrationLink}
					target="_blank"
					rel="noopener noreferrer"
					className="button w-button"
					style={{
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "10px",
						textDecoration: "none",
					}}
				>
					Register Now
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						style={{
							width: "16px",
							height: "16px",
							fill: "currentColor",
						}}
					>
						<path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-128c0-17.7-14.3-32-32-32L352 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z" />
					</svg>
				</a>
			</div>
			<div className="classinfotext">
				<p className="classdesc">
					<RichText text={data.description}></RichText>
				</p>
				<p className="classdesc activities">
					<RichText text={data.activitiesDescription}></RichText>
				</p>
			</div>

			<div className="classTagRow">
				{data.category &&
					data.category.map((el) => {
						return (
							<div key={uuid()} className="classtagdiv">
								<div className="classtag">{el.value}</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default Classes;
