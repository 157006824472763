import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

const ClassesDropdown = () => {
	const [categories, setCategories] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
	const navigate = useNavigate();

	// Get classes data from Redux store
	const { data } = useSelector((state) => state.cms);
	const collectionData = data["classes"];

	// Memoized resize handler
	const handleResize = useCallback(() => {
		setIsMobile(window.innerWidth <= 991);
	}, []);

	useEffect(() => {
		// Add resize event listener
		window.addEventListener("resize", handleResize);

		// Cleanup listener
		return () => window.removeEventListener("resize", handleResize);
	}, [handleResize]);

	useEffect(() => {
		if (collectionData) {
			// Extract unique categories
			const nonUniqueCategories = collectionData.flatMap((item) =>
				item.attributes.category.map((cat) => cat.value)
			);
			const uniqueCategories = [...new Set(nonUniqueCategories)].sort();
			setCategories(uniqueCategories);
		}
	}, [collectionData]);

	const handleCategoryClick = (category) => {
		// Close dropdown
		setIsOpen(false);

		// Navigate to Classes page with category hash
		navigate("/Classes", {
			state: { category }, // Optional: pass category as state
			hash: encodeURIComponent(category),
		});

		// Smooth scroll (if needed)
		setTimeout(() => {
			const filterSection = document.querySelector(".classeslistsection");
			if (filterSection) {
				filterSection.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}, 100);
	};

	// Handle base 'Classes' link click
	const handleClassesClick = () => {
		// If on Classes page already, might want to reset or do something specific
		navigate("/Classes");
		setIsOpen(false);
	};

	const toggleDropdown = () => {
		if (isMobile) {
			setIsOpen(!isOpen);
		}
	};

	const mouseEvents = isMobile
		? {}
		: {
				onMouseEnter: () => setIsOpen(true),
				onMouseLeave: () => setIsOpen(false),
		  };

	return (
		<div className="nav-dropdown-container" {...mouseEvents}>
			<div
				className={`navlink w-nav-link ${isOpen ? "is-active" : ""}`}
				onClick={isMobile ? toggleDropdown : handleClassesClick}
				style={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					gap: "4px",
				}}
			>
				Classes
				{!isOpen ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						style={{
							width: "12px",
							height: "12px",
							display: "inline-block",
							verticalAlign: "middle",
						}}
						fill="currentColor"
					>
						<path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
					</svg>
				) : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
						style={{
							width: "12px",
							height: "12px",
							display: "inline-block",
							verticalAlign: "middle",
						}}
						fill="currentColor"
					>
						<path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
					</svg>
				)}
			</div>
			{isOpen && categories.length > 0 && (
				<div className="nav-dropdown-menu">
					{categories.map((category) => (
						<div
							key={uuid()}
							className="nav-dropdown-item w-dropdown-link"
							onClick={() => handleCategoryClick(category)}
							style={{ cursor: "pointer" }}
						>
							{category}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ClassesDropdown;
